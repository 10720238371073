/*
 |-----------------------------------------------------------------------------
 | components/atoms/Button/Button.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get, isEmpty } from 'lodash';
// import { ray } from 'node-ray/web';
import classNames from 'classnames';
import dynamic from 'next/dynamic';

import * as IButton from './types';

import styles from './Button.module.scss';

const variants = {
	primary: 'primary',
	secondary: 'secondary',
	tertiary: 'tertiary',
};

const Link = dynamic(() => import('next/link'));

const Button: React.FC<IButton.IProps> = ({
	className,
	isDisabled,
	label,
	path,
	type,
	url,
	variant,
}) => {
	const classes = classNames(
		get(styles, variants[variant]),
		{
			[get(styles, 'isDisabled')]: isDisabled,
		},
		className
	);

	// ray('Debug atom Button:', {
	// 	className: className,
	// 	isDisabled: isDisabled,
	// 	label: label,
	// 	path: path,
	// 	type: type,
	// 	url: url,
	// 	variant: variant,
	// }).red();

	switch (true) {
		case !isEmpty(path):
			return (
				<Link href={path} passHref>
					<a className={classes} href="jsx-a11y">
						{label}
					</a>
				</Link>
			);
		case !isEmpty(url):
			return (
				<a
					className={classes}
					href={url}
					rel="external noopener noreferrer"
					target="_blank"
				>
					<span className={get(styles, 'wrapper')}>
						{label}

						<svg className={get(styles, 'icon')} aria-hidden="true">
							<use xlinkHref="/imgs/icn_sprite.svg#external"></use>
						</svg>
					</span>
				</a>
			);
		default:
			return (
				<button className={classes} disabled={isDisabled} type={type}>
					{label}
				</button>
			);
	}
};

export default Button;
